import {createGlobalStyle} from "styled-components"

export const GlobalStyles = createGlobalStyle`

  :root {

    /* dark shades of primary color*/
    --clr-primary-1: hsl(184, 91%, 17%);
    --clr-primary-2: hsl(185, 84%, 25%);
    --clr-primary-3: hsl(185, 81%, 29%);
    --clr-primary-4: hsl(184, 77%, 34%);
    --clr-primary-5: #2caeba;
    /* lighter shades of primary color */
    --clr-primary-6: hsl(185, 57%, 50%);
    --clr-primary-7: hsl(184, 65%, 59%);
    --clr-primary-8: hsl(184, 80%, 74%);
    --clr-primary-9: hsl(185, 94%, 87%);
    --clr-primary-10: hsl(186, 100%, 94%);
    /* darkest grey - used for headings */
    --clr-grey-1: hsl(209, 61%, 16%);
    --clr-grey-3: hsl(209, 34%, 30%);
    --clr-grey-4: hsl(209, 28%, 39%);
    /* grey used for paragraphs */
    --clr-grey-5: hsl(210, 22%, 49%);
    --clr-grey-8: hsl(210, 31%, 80%);
    --clr-grey-9: hsl(212, 33%, 89%);
    --clr-grey-10: hsl(210, 36%, 96%);
    --clr-green-dark: hsl(125, 67%, 35%);
    --clr-red-dark: hsl(360, 67%, 44%);
    --clr-red-light: hsl(360, 71%, 66%);
    --clr-white: #fff;
    --clr-maivita-green: rgba(32, 176, 56, 0.99);
    --clr-maivita-light-green: rgba(32, 176, 56, 0.55);
    --clr-maivita-dark-green: rgba(44, 164, 64, 0.99);
    --clr-gray-50: rgb(249 250 251);
    --ff-primary: "Quicksand", sans-serif;
    --ff-secondary: "Quicksand", sans-serif;
    --transition: all 0.3s linear;
    --bezier-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    --spacing: 0.15rem;
    --spacing-nav: 0.15rem;
    --radius: 0.25rem;
    --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    --max-width: 1170px;
    --max-width-section-text: 960px;
    --fixed-width: 800px;
  }


  /*
===============
Global Styles
===============
*/

  *,
  ::after,
  ::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: var(--ff-secondary);
    background: var(--clr-white);
    color: var(--clr-grey-1);
    line-height: 1.5;
    font-size: 0.875rem;
    margin-top: 5rem;
  }

  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4 {
    letter-spacing: var(--spacing);
    line-height: 1.25;
    margin-bottom: 0.75rem;
    font-family: var(--ff-primary);

    a {
      color: var(--clr-maivita-green);
    }
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
    margin-top: 2em;
    margin-bottom: 1.5em;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 0.875rem;
  }

  input, textarea {
    font-family: var(--ff-primary);
  }

  p {
    font-size: 1.1em;
    margin-bottom: 1.25rem;
    color: var(--clr-grey-3);

    a {
      color: var(--clr-maivita-green);
    }
  }

  ul {
    margin-bottom: 1.5em;
  }

  li {
    font-size: 1.2em;
    margin-left: 20px;

    a {
      color: var(--clr-maivita-green);
    }
  }

  @media screen and (min-width: 800px) {
    h1 {
      font-size: 4rem;
    }

    h2 {
      font-size: 2.5rem;
    }

    h3 {
      font-size: 1.75rem;
    }

    h4 {
      font-size: 1rem;
    }

    body {
      font-size: 1rem;
    }

    h1,
    h2,
    h3,
    h4 {
      line-height: 1;
    }
  }

  /*  global classes */

  .text-center {
    text-align: center;
  }

  .ml-3 {
    margin-left: 2rem;
  }

  .mt-3 {
    margin-top: 2rem;
  }

  .mt-5 {
    margin-top: 4rem;
  }

  .mb-3 {
    margin-bottom: 2rem;
  }

  .mb-5 {
    margin-bottom: 4rem;
  }

  .btn-maivita {
    text-transform: uppercase;
    font-family: var(--ff-secondary);
    background: var(--clr-maivita-dark-green);
    color: var(--clr-grey-10);
    padding: 0.375rem 0.75rem;
    letter-spacing: var(--spacing);
    display: inline-block;
    font-weight: 600;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    font-size: 0.875rem;
    border: 2px solid transparent;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: var(--radius);
  }

  .btn-maivita:hover {
    color: var(--clr-white);
    background: var(--clr-maivita-green);
  }
  
  .btn {
    text-transform: uppercase;
    font-family: var(--ff-secondary);
    background: var(--clr-primary-5);
    color: var(--clr-primary-9);
    padding: 0.375rem 0.75rem;
    letter-spacing: var(--spacing);
    display: inline-block;
    font-weight: 600;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    font-size: 0.875rem;
    border: 2px solid transparent;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: var(--radius);
  }

  .btn:hover {
    color: var(--clr-primary-1);
    background: var(--clr-primary-8);
  }

  .center-btn {
    display: block;
    width: 12rem;
    text-align: center;
    margin: 0 auto;
    margin-top: 3rem;
  }

  /* section */
  .section {
    padding: 5rem 0;
  }

  .section-center {
    width: 90vw;
    margin: 0 auto;
    max-width: 1170px;
  }

  @media screen and (min-width: 992px) {
    .section-center {
      width: 95vw;
    }
  }

  /* added during recording */
  .underline {
    width: 5rem;
    height: 0.25rem;
    margin-bottom: 1.25rem;
    background: var(--clr-primary-5);
    margin-left: auto;
    margin-right: auto;
  }

  .section-title {
    margin-bottom: 4rem;
    text-align: center;
  }

  .bg-grey {
    background: var(--clr-grey-10);
  }

  /* page links */

  /* social links */
  .social-links {
    width: 15rem;
    display: flex;
    gap: 30px;
    justify-content: center;
  }

  .social-link {
    font-size: 1.75rem;
    color: var(--clr-grey-1);
    transition: var(--transition);
  }

  .social-link:hover {
    color: var(--clr-primary-8);
  }
  
  .social-icon {
    color: var (--clr-white);
  }

  /*
  ===============
  Sidebar
  ===============
  */
  .sidebar {
    background: var(--clr-maivita-green);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3333333;
    display: grid;
    place-items: center;
    opacity: 0;
    overflow: auto;
    padding-top: 2rem;
    transition: var(--bezier-transition);
    transform: translateX(-100%);
  }

  .show-sidebar {
    opacity: 1;
    transform: translateX(0);
  }

  .sidebar-links li {
    opacity: 0;
  }

  .sidebar-links li a {
    display: block;
    text-align: center;
    color: var(--clr-grey-10);
    letter-spacing: var(--spacing);
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    transition: var(--transition);
    border-radius: var(--radius);
  }

  .sidebar-links li a:hover {
    background: var(--clr-maivita-dark-green);
    color: white;
    font-weight: bold;
  }

  .close-btn {
    position: absolute;
    right: 4.75%;
    top: 2.75%;
    font-size: 2.5rem;
    background: transparent;
    border-color: transparent;
    color: var(--clr-grey-3);
    cursor: pointer;
  }

  @media screen and (min-width: 992px) {
    .sidebar {
      transform: translateX(-100%);
    }
  }

  /*
  ===============
  Sidebar Animation
  ===============
  */
  .sidebar-links li {
    animation: slideRight 0.3s ease-in-out 0.2s forwards;
  }

  /*
    .sidebar-links li:nth-of-type(1) {
      animation-delay: 0.2s;
    }
  
    .sidebar-links li:nth-of-type(2) {
      animation-delay: 0.4s;
    }
  
    .sidebar-links li:nth-of-type(3) {
      animation-delay: 0.6s;
    }
  
    .sidebar-links li:nth-of-type(4) {
      animation-delay: 0.8s;
    }
  
    .sidebar-links li:nth-of-type(5) {
      animation-delay: 1s;
    }
  */
  @keyframes slideRight {
    0% {
      transform: translateX(-200px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .sidebar-icons li {
    opacity: 0;
    animation: slideUp 0.5s ease-in-out 0.3s forwards;
  }

  .sidebar-icons li a {
    color: var(--clr-white);
  }

  .sidebar-icons li:nth-of-type(1) {
    animation-delay: 0.25s;
  }

  .sidebar-icons li:nth-of-type(2) {
    animation-delay: 0.5s;
  }

  .sidebar-icons li:nth-of-type(3) {
    animation-delay: 0.75s;
  }

  .sidebar-icons li:nth-of-type(4) {
    animation-delay: 1s;
  }

  .sidebar-icons li:nth-of-type(5) {
    animation-delay: 1.25s;
  }

  @keyframes slideUp {
    0% {
      transform: translateY(200px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .leafletContainer {
    width: 100%;
    height: 400px;
    overflow-x: hidden;

    @media (min-width: 1024px) {
      height: 450px;
    }
  }
  
  /*
  .slideshowPlayBtn {
    display: none;
  }
*/
  
  .lightboxjs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    position: relative;
    margin-top: 50px;
    padding: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
      transition: all 0.3s linear;

      &:hover {
        transform: scale(1.02);
      }
    }

    .grid-element {
      animation: animateGrid 0.5s;
    }

    @keyframes animateGrid {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    @media screen and (max-width: 768px) {
      h3 {
        font-size: 1.5rem;
      }
    }
  }

  .lightboxjs img {
    min-height: 290px;
    width: 100%;
    object-fit: cover;
  }
`
