const data = [
    {
        id: 1,
        text: "koti",
        url: "/",
    },
    {
        id: 2,
        text: "Riippuvuushoidot",
        subMenuItems: [
            {
                id: 21,
                text: "Päihderiippuvuus",
                url: "/paihderiippuvuus"
            },
            {
                id: 22,
                text: "Peliriippuvuus",
                url: "/peliriippuvuus"
            },
            {
                id: 23,
                text: "Läheisriippuvuus",
                url: "/laheisriippuvuus"
            },
            {
                id: 24,
                text: "Alkoholismi",
                url: "/alkoholismi"
            },
            {
                id: 25,
                text: "Hoitoon tulo",
                url: "/minnesota-hoito"
            },
            {
                id: 26,
                text: "Läheisille",
                url: "/laheisille"
            },
            {
                id: 27,
                text: "-",
                url: ""
            },
            {
                id: 28,
                text: "Maivita kuvia",
                url: "/maivita-paihdehoitolaitos-kuvia"
            },
        ]
    },
    {
        id: 3,
        text: "Ammattilaisille",
        url: "/paihdepalveluita-jarjestaville",
    },
    {
        id: 4,
        text: "Ajankohtaista",
        url: "/ajankohtaista",
    },
    {
        id: 5,
        text: "Yhteystiedot",
        subMenuItems: [
            {
                id: 51,
                text: "Henkilökuntamme",
                url: "/yhteystiedot/henkilokuntamme"
            },
            {
                id: 52,
                text: "Ota yhteyttä",
                url: "/yhteystiedot/ota-yhteytta"
            },
        ]
    },
]

export default data
